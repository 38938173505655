import { Card, CardMedia, Grid } from '@mui/material'
import SoftAvatar from 'components/SoftAvatar'
import SoftBox from 'components/SoftBox'
import React, { useState } from 'react'

import logo_andalanku from "assets/images/logo_andalanku.png"
import kolut_unggul from "assets/images/kolut_unggul.png"
import andalanku from 'assets/images/andalanku.png'
import bg_andalanku from "assets/images/bg_andalanku.png"
import { CustomDescrypt } from 'global/func'
import { decodeToken } from 'react-jwt'
import SoftTypography from 'components/SoftTypography'
import userStore from 'store/userStore'

const Headerx = () => {
    const { user } = userStore(state => ({ user: state.user }));
    const decryptedUser = (user === null || user === undefined || user === '') ? "" : JSON.parse(CustomDescrypt(user));
    const [decodedUser] = useState(decodeToken(decryptedUser));

    return (
        <SoftBox position="relative" sx={{ paddingTop: '0px !important' }}>
            <SoftBox
                display="grid"
                alignItems="center"
                position="relative"
                minHeight="22rem"
                paddingTop='0px !important'
                // marginTop='-10rem'
                // borderRadius="xl"
                sx={{
                    backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
                        `${linearGradient(
                            rgba(gradients.info.main, 0),
                            rgba(gradients.info.state, 0)
                        )}, url(${bg_andalanku})`,
                    backgroundSize: "cover",
                    backgroundPosition: "50%",
                    overflow: "hidden",
                }}
            >
                <Grid container justifyContent={{ lg: 'flex-start', xs: 'center' }} mt={{ lg: 0, xs: -7 }} >
                    <Grid item ml={{ lg: 5 }}>
                        {/* <SoftBox component={Image} src={giona} alt='giona' width='100%' height='215px' style={{transform: [{scaleX: -1}]}} /> */}
                        {/* <img src={giona} alt='giona' width='100%' height='215px' style={{transform: [{scaleX: -1}]}} /> */}
                        <CardMedia
                            src={andalanku}
                            component="img"
                            title={decodedUser?.status || ''}
                            sx={{
                                maxWidth: "100%",
                                height: { lg: '280px', xs: '280px' },
                                margin: 0,
                                // boxShadow: ({ boxShadows: { md } }) => md,
                                objectFit: "cover",
                                objectPosition: "center",
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container justifyContent='center' mt={-50} width='100%' display={{ xs: 'none', lg: 'flex' }}>
                    <Grid item>
                        <img src={kolut_unggul} alt='logo' width='100%' height='170px' style={{ transform: [{ scaleX: -1 }] }} />
                    </Grid>
                </Grid>
                {/* <Grid container justifyContent='center' mt={-58} width='100%' display={{ xs: 'none', lg: 'flex' }}>
                    <Grid item>
                        <img src={logo_group} alt='logo' width='100%' height='70px' style={{ transform: [{ scaleX: -1 }] }} />
                    </Grid>
                </Grid>
                <Grid container justifyContent={{ lg: 'center', xs: 'center' }} mt={-42} width='100%' display={{ xs: 'none', lg: 'flex' }}>
                    <Grid item>
                        <img src={wujudkan} alt='logo' width='100%' height='40px' style={{ transform: [{ scaleX: -1 }] }} />
                    </Grid>
                </Grid>
                <Grid container justifyContent={{ lg: 'center', xs: 'center' }} mt={-28} width='100%' display={{ xs: 'none', lg: 'flex' }}>
                    <Grid item>
                        <img src={jargon} alt='logo' width='100%' height='40px' style={{ transform: [{ scaleX: -1 }] }} />
                    </Grid>
                </Grid> */}
            </SoftBox>
            <Card
                sx={{
                    backdropFilter: `saturate(200%) blur(30px)`,
                    backgroundColor: ({ functions: { rgba }, palette: { background } }) => rgba(background.default, 0.8),
                    // backgroundColor: 'white',
                    // opacity: 0.8,
                    boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
                    position: "relative",
                    mt: -8,
                    mx: 3,
                    py: 2,
                    px: 2,
                }}
            >
                <SoftBox>
                    <Grid container spacing={2} justifyContent='flex-start'>
                        <Grid item xs='auto' >
                            <SoftAvatar
                                src={logo_andalanku}
                                alt="profile-image"
                                variant="rounded"
                                size="xl"
                                shadow="sm"
                            />
                        </Grid>
                        <Grid item>
                            <SoftBox height="100%" my={1} lineHeight={1} width="100%">
                                <SoftTypography variant="h5" width="100%">
                                    {/* {decryptedUser.role === '2' ? 'Admin' : 'Tim'} */}
                                    {decodedUser?.nama || ''}
                                </SoftTypography>
                                <SoftTypography variant="body2" width="100%">
                                    {decodedUser?.status || ''}
                                </SoftTypography>
                            </SoftBox>
                        </Grid>
                    </Grid>
                </SoftBox>
            </Card>
        </SoftBox>
    )
}

export default Headerx