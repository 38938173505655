import { ArrowBack, ExpandMore, Save } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Card, CardContent, CardHeader, CircularProgress, Divider, Grid, IconButton, List, ListItem, ListItemText, TextField, Tooltip, Typography } from '@mui/material'
import SoftBox from 'components/SoftBox'
import SoftInput from 'components/SoftInput'
import SoftTypography from 'components/SoftTypography'
import { CustomDescrypt } from 'global/func'
import { postSubmit } from 'global/postFunc'
import { postDataWithResponse } from 'global/postFunc'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import userStore from 'store/userStore'

const TambahVg = () => {
    const { user, addReq } = userStore(state => ({ user: state.user, addReq: state.addReq }));
    const decryptedUser = (user === null || user === undefined || user === '') ? "" : JSON.parse(CustomDescrypt(user));
    
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [listDpt, setListDpts] = useState([])
    const [dpt, setDpt] = useState({})

    const location = useLocation();
    const data = location.state;

    const [formData, setFormData] = useState({
        nik: '',
        nama: '',
        no_hp: '',
        id_kec: data.id_kec,
        nama_kec: data.nama_kec,
        id_kel: data.id_kel,
        nama_kel: data.nama_kel,
        nik_leader: data.nik,
        nama_leader: data.nama,
        status: 'Vote Getter'
    })

    const submit = (event) => {
        event.preventDefault();
        setLoading(true)
        addReq()
        postSubmit(
            '/entry/vote-getter/insert',
            formData,
            decryptedUser,
            afterSubmit,
            (error) => {
                setLoading(false)
                console.log(error);
            }
        )
    }

    const afterSubmit = () => {
        setLoading(false)
        navigate(-1)
    }

    const reqDpt = () => {
        addReq()
        setLoading(true)
        postDataWithResponse(
            '/dpt',
            {
                id_kel: data.id_kel,
                status: ''
            },
            decryptedUser,
            (res) => {
                setLoading(false)
                setListDpts(res)
            },
            (error) => {
                setLoading(false)
                console.log(error)
            }
        )
    }

    useEffect(() => {
        reqDpt()
        console.log(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // useEffect(() => {
    //     console.log(formData);
    // }, [formData])

    return (
        <SoftBox mb={3}>
            <Grid container spacing={3} mt={3} justifyContent='center'>
                <Grid item xs={12} lg={9}>
                    <Card>
                        <CardHeader
                            title={
                                <SoftTypography variant='h6'>Kembali</SoftTypography>
                            }
                            avatar={
                                <Tooltip title="Kembali">
                                    <IconButton variant="outlined" onClick={() => navigate(-1)}><ArrowBack /></IconButton>
                                </Tooltip>
                            }
                        />
                        <CardContent>
                            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                <ListItem>
                                    <ListItemText primary={
                                        <Typography variant='subtitle2'>
                                            Kelurahan
                                        </Typography>
                                    } secondary={
                                        <Typography variant='body2'>
                                            {data.nama_kel}
                                        </Typography>
                                    } />
                                </ListItem>
                                <Divider variant="inset" component="li" />
                                <ListItem>
                                    <ListItemText primary={
                                        <Typography variant='subtitle2'>
                                            NIK Relawan
                                        </Typography>
                                    } secondary={
                                        <Typography variant='body2'>
                                            {data.nik}
                                        </Typography>
                                    } />
                                </ListItem>
                                <Divider variant="inset" component="li" />
                                <ListItem>
                                    <ListItemText primary={
                                        <Typography variant='subtitle2'>
                                            Nama Relawan
                                        </Typography>
                                    } secondary={
                                        <Typography variant='body2'>
                                            {data.nama}
                                        </Typography>
                                    } />
                                </ListItem>
                                <Divider variant="inset" component="li" />
                            </List>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Grid container spacing={3} justifyContent='center' py={5}>
                <Grid item xs={12} sm={12} md={9}>
                    <Card >
                        {
                            loading ?
                                (
                                    <Grid container spacing={3} py={5} justifyContent="center">
                                        <Grid item>
                                            <Box sx={{ display: 'flex' }}>
                                                <CircularProgress />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                )
                                :
                                (
                                    <CardContent component='form' onSubmit={submit}>
                                        <Grid container justifyContent='center' direction='column'>
                                            <Grid item xs={12} sm={12} lg={6}>
                                                <SoftBox mb={2} justifyContent="center">
                                                    <SoftBox mb={1} ml={0.5}>
                                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                            Kecamatan
                                                        </SoftTypography>
                                                    </SoftBox>
                                                    <SoftInput
                                                        type="text"
                                                        defaultValue={formData.nama_kec}
                                                        // onChange={(event) => setFormData({ ...formData, username: event.target.value })}
                                                        autoComplete='off'
                                                        disabled
                                                    />
                                                </SoftBox>
                                            </Grid>
                                            <Grid item xs={12} sm={12} lg={6}>
                                                <SoftBox mb={2} justifyContent="center">
                                                    <SoftBox mb={1} ml={0.5}>
                                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                            Kelurahan
                                                        </SoftTypography>
                                                    </SoftBox>
                                                    <SoftInput
                                                        type="text"
                                                        defaultValue={formData.nama_kel}
                                                        // onChange={(event) => setFormData({ ...formData, username: event.target.value })}
                                                        autoComplete='off'
                                                        disabled
                                                    />
                                                </SoftBox>
                                            </Grid>
                                            <Grid item xs={12} sm={12} lg={6}>
                                                <SoftBox mb={2} justifyContent="center">
                                                    <SoftBox mb={1} ml={0.5}>
                                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                            Nama
                                                        </SoftTypography>
                                                    </SoftBox>
                                                    <Autocomplete
                                                        // disablePortal
                                                        freeSolo
                                                        id="combo-box-demo"
                                                        options={listDpt}
                                                        fullWidth
                                                        getOptionLabel={(option) => option.nik + ' ' + option.nama}
                                                        // getOptionLabel={(option) => option.nama}
                                                        onChange={(event, newValue) => {
                                                            newValue !== null && setFormData({ ...formData, nik: newValue.nik, nama: newValue.nama })
                                                            newValue !== null && setDpt(newValue)
                                                        }}
                                                        onInputChange={(event, newInputValue) => {
                                                            newInputValue === "" && setFormData({ ...formData, nik: '', nama: '' })
                                                            newInputValue === "" && setDpt({})
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                            />
                                                        )}
                                                    />
                                                </SoftBox>
                                            </Grid>
                                            <Grid item xs={12} sm={12} lg={6}>
                                                <SoftBox mb={2} justifyContent="center">
                                                    <SoftBox mb={1} ml={0.5}>
                                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                            No. HP
                                                        </SoftTypography>
                                                    </SoftBox>
                                                    <SoftInput
                                                        type="text"
                                                        defaultValue={formData.no_hp}
                                                        onChange={(event) => setFormData({ ...formData, no_hp: event.target.value })}
                                                        autoComplete='off'
                                                        required
                                                        inputProps={{ maxLength: 12 }}
                                                    />
                                                </SoftBox>
                                            </Grid>
                                            {
                                                formData.nik !== '' && (
                                                    <Grid container spacing={3} pt={3} justifyContent="center">
                                                        <Grid item xs={12} sm={12} md={10} lg={8}>
                                                            <Accordion>
                                                                <AccordionSummary
                                                                    expandIcon={<ExpandMore />}
                                                                    aria-controls="panel1-content"
                                                                    id="panel1-header"
                                                                >
                                                                    <Typography variant='button'>
                                                                        Detail DPT
                                                                    </Typography>
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <List sx={{ bgcolor: 'background.paper' }}>
                                                                        <ListItem>
                                                                            <ListItemText primary={
                                                                                <Typography
                                                                                    variant="body2"
                                                                                    color="text.primary"
                                                                                >
                                                                                    NIK
                                                                                </Typography>
                                                                            }
                                                                                secondary={
                                                                                    <Typography
                                                                                        variant="body2"
                                                                                        color="text.primary"
                                                                                    >
                                                                                        {dpt.nik ? dpt.nik : '-'}
                                                                                    </Typography>
                                                                                } />
                                                                        </ListItem>
                                                                        <Divider />
                                                                        <ListItem>
                                                                            <ListItemText primary={
                                                                                <Typography
                                                                                    variant="body2"
                                                                                    color="text.primary"
                                                                                >
                                                                                    No. KK
                                                                                </Typography>
                                                                            }
                                                                                secondary={
                                                                                    <Typography
                                                                                        variant="body2"
                                                                                        color="text.primary"
                                                                                    >
                                                                                        {dpt.no_kk ? dpt.no_kk : '-'}
                                                                                    </Typography>
                                                                                } />
                                                                        </ListItem>
                                                                        <Divider />
                                                                        <ListItem>
                                                                            <ListItemText primary={
                                                                                <Typography
                                                                                    variant="body2"
                                                                                    color="text.primary"
                                                                                >
                                                                                    Nama
                                                                                </Typography>
                                                                            }
                                                                                secondary={
                                                                                    <Typography
                                                                                        variant="body2"
                                                                                        color="text.primary"
                                                                                    >
                                                                                        {dpt.nama ? dpt.nama : '-'}
                                                                                    </Typography>
                                                                                } />
                                                                        </ListItem>
                                                                        <Divider />
                                                                        <ListItem>
                                                                            <ListItemText
                                                                                primary={
                                                                                    <Typography
                                                                                        variant="body2"
                                                                                        color="text.primary"
                                                                                    >
                                                                                        Tempat/Tgl Lahir
                                                                                    </Typography>
                                                                                }
                                                                                secondary={
                                                                                    <Typography
                                                                                        variant="body2"
                                                                                        color="text.primary"
                                                                                    >
                                                                                        {dpt.tempat_lahir ? dpt.tempat_lahir + ', ' + dpt.tgl_lahir : '-'}
                                                                                    </Typography>
                                                                                }
                                                                            />
                                                                        </ListItem>
                                                                        <Divider />
                                                                        <ListItem>
                                                                            <ListItemText
                                                                                primary={
                                                                                    <Typography
                                                                                        variant="body2"
                                                                                        color="text.primary"
                                                                                    >
                                                                                        Status Kawin
                                                                                    </Typography>
                                                                                }
                                                                                secondary={
                                                                                    <Typography
                                                                                        variant="body2"
                                                                                        color="text.primary"
                                                                                    >
                                                                                        {
                                                                                            dpt.stts_kawin
                                                                                        }
                                                                                    </Typography>
                                                                                }
                                                                            />
                                                                        </ListItem>
                                                                        <Divider />
                                                                        <ListItem>
                                                                            <ListItemText
                                                                                primary={
                                                                                    <Typography
                                                                                        variant="body2"
                                                                                        color="text.primary"
                                                                                    >
                                                                                        Jenis Kelamin
                                                                                    </Typography>
                                                                                }
                                                                                secondary={
                                                                                    <Typography
                                                                                        variant="body2"
                                                                                        color="text.primary"
                                                                                    >
                                                                                        {dpt.jenkel === 'L' ? 'Laki-laki' : 'Perempuan'}
                                                                                    </Typography>
                                                                                }
                                                                            />
                                                                        </ListItem>
                                                                        <Divider />
                                                                        <ListItem>
                                                                            <ListItemText
                                                                                primary={
                                                                                    <Typography
                                                                                        variant="body2"
                                                                                        color="text.primary"
                                                                                    >
                                                                                        Alamat
                                                                                    </Typography>
                                                                                }
                                                                                secondary={
                                                                                    <Typography
                                                                                        variant="body2"
                                                                                        color="text.primary"
                                                                                    >
                                                                                        {dpt.alamat ? `${dpt.alamat} RT. ${dpt.rt} RW. ${dpt.rw}, Kel. ${dpt.nama_kel}, Kec. ${dpt.nama_kec}, Kota. ${dpt.nama_kab}` : '-'}
                                                                                    </Typography>
                                                                                } />
                                                                        </ListItem>
                                                                        <Divider />
                                                                        <ListItem>
                                                                            <ListItemText
                                                                                primary={
                                                                                    <Typography
                                                                                        variant="body2"
                                                                                        color="text.primary"
                                                                                    >
                                                                                        RT / RW
                                                                                    </Typography>
                                                                                }
                                                                                secondary={
                                                                                    <Typography
                                                                                        variant="body2"
                                                                                        color="text.primary"
                                                                                    >
                                                                                        RT. {dpt.rt}, RW. {dpt.rw}
                                                                                    </Typography>
                                                                                } />
                                                                        </ListItem>
                                                                        <Divider />
                                                                        <ListItem>
                                                                            <ListItemText primary={
                                                                                <Typography
                                                                                    variant="body2"
                                                                                    color="text.primary"
                                                                                >
                                                                                    TPS
                                                                                </Typography>
                                                                            }
                                                                                secondary={
                                                                                    <Typography
                                                                                        variant="body2"
                                                                                        color="text.primary"
                                                                                    >
                                                                                        {dpt.nama_tps}
                                                                                    </Typography>
                                                                                } />
                                                                        </ListItem>
                                                                        <Divider />
                                                                    </List>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            }
                                            <Grid item xs={12} sm={12} lg={6}>
                                                <Grid container justifyContent='flex-end'>
                                                    <Grid item>
                                                        <SoftBox mb={1} ml={0.5}>
                                                            <LoadingButton
                                                                loading={loading}
                                                                loadingPosition="start"
                                                                startIcon={<Save />}
                                                                variant="contained"
                                                                type='submit'
                                                            >
                                                                Save
                                                            </LoadingButton>
                                                        </SoftBox>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                )
                        }

                    </Card>
                </Grid>
            </Grid>
        </SoftBox>
    )
}

export default TambahVg