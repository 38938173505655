import Configurator from 'components/Configurator'
import DashboardLayout from 'components/DashboardLayout'
import DashboardNavbar from 'components/DashboardNavbar'
import Sidenav from 'components/Sidenav'
import React, { useEffect, useState } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { Settings } from '@mui/icons-material'

import logo from 'assets/images/logo_andalanku.png'
import { CustomDescrypt } from 'global/func'
import userStore from 'store/userStore'
import { useSoftUIController } from 'context'
import { decodeToken, isExpired } from 'react-jwt'
import { setMiniSidenav } from 'context'
import { setOpenConfigurator } from 'context'
import SoftBox from 'components/SoftBox'
import KorcamRoutes from './KorcamRoutes'
import Dashboard from './Dashboard'
import Pendataan from './PendataanDTD'
import Korkel from './Korkel'
import PertemuanTerbatas from './Pt'
import VoteGetter from './VoteGetter'

const TemplateKorcam = () => {
    const navigate = useNavigate();
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)
    const pathname = useLocation();
    const [controller, dispatch] = useSoftUIController();
    const { miniSidenav, direction, openConfigurator, sidenavColor } = controller;
    const [onMouseEnter, setOnMouseEnter] = useState(false);

    useEffect(() => {
        console.log(isExpired(decryptedUser));
        if (encryptUser === null || encryptUser === undefined) {
            navigate("/", { replace: true });
        }
        else if (isExpired(decryptedUser)) {
            console.log(isExpired(decryptedUser));
            logOut();
            navigate("/", { replace: true });
        }
        else {
            if (decryptedUser.user === null) {
                navigate("/", { replace: true });
            }
            else {
                const decodedUser = decodeToken(decryptedUser);
                // console.log(decodedUser);
                if (decodedUser.role !== 'korcam') {
                    navigate("/", { replace: true });
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [encryptUser, pathname, decryptedUser]);

    const handleOnMouseEnter = () => {
        if (miniSidenav && !onMouseEnter) {
            setMiniSidenav(dispatch, false);
            setOnMouseEnter(true);
        }
    };

    // Close sidenav when mouse leave mini sidenav
    const handleOnMouseLeave = () => {
        if (onMouseEnter) {
            setMiniSidenav(dispatch, true);
            setOnMouseEnter(false);
        }
    };

    // Change the openConfigurator state
    const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

    // Setting the dir attribute for the body element
    useEffect(() => {
        document.body.setAttribute("dir", direction);
    }, [direction]);

    // Setting page scroll to 0 when changing the route
    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, [pathname]);

    const configsButton = (
        <SoftBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="3.5rem"
            height="3.5rem"
            bgColor="white"
            shadow="sm"
            borderRadius="50%"
            position="fixed"
            right="2rem"
            bottom="2rem"
            zIndex={99}
            color="dark"
            sx={{ cursor: "pointer" }}
            onClick={handleConfiguratorOpen}
        >
            <Settings fontSize="default" color="inherit" />
        </SoftBox>
    );

    return (
        <>
            <Sidenav
                color={sidenavColor}
                brand={logo}
                brandName=""
                routes={KorcamRoutes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
                homePage='/korcam' />

            <Configurator />
            {configsButton}

            <DashboardLayout>
                <DashboardNavbar homePage='/korcam' isMini={miniSidenav} />
                <Routes>
                    <Route exact path="" element={<Dashboard />} />
                    <Route exact path="dtd/*" element={<Pendataan />} />
                    {/* <Route exact path="apk/*" element={<Apk />} /> */}
                    <Route exact path="pt/*" element={<PertemuanTerbatas />} />
                    <Route exact path="vote-getter/*" element={<VoteGetter />} />
                    <Route exact path="korkel/*" element={<Korkel />} />

                </Routes>
            </DashboardLayout>
        </>
    )
}

export default TemplateKorcam